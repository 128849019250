<template>
	<div class="cart-contain">
		<b-container>
			<div class="CART_CONTAINER">
				<div class="cart">CART</div>
				<b-row>
					<b-col style="display: flex; justify-content: flex-start">
						<img class="back" src="../assets/back.svg" @click="$router.push('/')" />
					</b-col>
				</b-row>
				<b-row>
					<div class="col-md-8">
						<div class="cartItem" v-for="(item, index) in data" :key="index" :index="index">
							<div class="itemCard" v-if="hasData">
								<div class="row" style="padding: 2% 0 0 0; margin: 0">
									<div class="col-md-2">
										<img v-if="cartItems" :src="getImage(cartItems[index].img)" class="img" />
									</div>
									<div class="col">
										<div class="item_name" style="margin: 0 0 10px 0">
											{{ cartItems[index].name }}
										</div>
										<div class="row">
											<div class="col">
												Adult: {{ cartItems[index].adults }}
											</div>
											<div class="col">
												Child: {{ cartItems[index].child }}
											</div>
											<div class="col">
												Infant: {{ cartItems[index].infant }}
											</div>
											<div class="col">
												Date: {{ cartItems[index].date }}
											</div>
										</div>
										<div class="col-md-12" style="margin: 10px 0 0 0">
											<div class="row">
												<div class="col" v-if="item.duration">
													Duration: {{ item.duration }} Hours
												</div>
												<div class="col"
													style="display: flex; justify-content:center; flex-direction: column">
													<b>Price: AED {{ item.total }}</b>
												</div>
											</div>
										</div>
									</div>
									<div @click="del(cartItems[index], index)" class="col-md-1">
										<img :src="require('../assets/trash.svg')" class="delete-icon" />
									</div>
								</div>
							</div>
							<div class="row" v-if="hasData">
								<div class="col-md-12" style="width: 100%; display:flex; justify-content:flex-end">
									<b-button variant="dark" class="psg-button" @click="details(index, item)"
										v-if="!isPsgSuccess(index)">Add Passenger Details</b-button>
									<b-button variant='success' class="psgdetails" v-if="isPsgSuccess(index)" disabled>
										<b-icon icon="check-square" animation="fade"></b-icon>
									</b-button>
								</div>
							</div>
						</div>
					</div>
					<div class="emptycart" v-if="!hasData">
						<div class="" style="text-align:center">
							<h3>There are currently no items in your Cart !</h3>
						</div>
					</div>
					<b-col md="4" v-if="hasData" style="height: 210px">
						<div class="sidebar" style="text-align:center">

							<div><b>Packages</b>: {{ data.length }}</div>
							<div><b>SubTotal</b>: AED {{ subtotal }}</div>
							<div><b>Grand Total</b>: AED {{ grandtotal }} <sub>[ Inclusive of VAT ]</sub></div>
							<b-row>
								<b-col>
									<b-button variant="dark" class="placeorder" :disabled="isDisabled" @click="checkout()">Send
										Order</b-button>
								</b-col>
							</b-row>
							<div class="msg" v-if="msg">
								<span>{{ msg }}</span>
							</div>
						</div>
					</b-col>
				</b-row>

			</div>


		</b-container>

	</div>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from "../components/Navbar.vue";

export default {
   name: "Cart",

   data(){
       return{
         data: {},
			hasData: false,
			subtotal: 0,
			grandtotal: 0,
			passengers: {
				adult: 0,
				child: 0,
				infant: 0
			},
			passengersDetails: [],
			isPsgValid: false,
			psgDetails: this.$store.state.psgdetails.passengers,
			isDisabled: true,
			renderComponent: true,
			msg: null
       }
   },

    component:{
        Navbar,
    },

	 computed: {

      ...mapState("cart", ["cartItems"]),

	 },

	mounted() {
		this.data = this.$store.state.cart.cartItems
		if(this.data.length) {this.hasData = true}

		if(this.hasData) {
			this.totalPrice()
		}

		if(!_.isEmpty(JSON.parse(localStorage.getItem('psgDetails')))) {
			this.psgDetails = JSON.parse(localStorage.getItem('psgDetails'))
		}
	},

	methods: {
		del(object, index){
			this.$store.dispatch('cart/deleteCart', object)
			this.totalPrice()
			this.$store.dispatch('psgdetails/updatePassengers', index)
			if(this.$store.state.cart.cartItems.length === 0) {
				return this.hasData = false
			}
			return
		},
		totalPrice() {
			let total = 0
			for(let i=0; i<this.data.length; i++) {
				total += this.data[i].total
			}
			this.subtotal = total

			if(this.subtotal) {
				return this.grandtotal = ((this.subtotal * 0.05) + this.subtotal).toFixed(2)
			}
		},
		details(index, object) {

			object.index = index

			this.$router.push('/passenger/'+ index)

		},

		getImage(name) {
				return 'https://www.desertlinktours.com/api/product-images/' + name
		},

		isPsgSuccess(index) {
			if(this.$store.state.psgdetails.passengers[index]) {
				if(this.$store.state.psgdetails.passengers[index].isPsgComplete) {
					this.isPsgValid = true
					this.isDisabled = false
					return true
				}
			} else {
				this.isDisabled = true
				return false
			}
		},

		async checkout() {
			let vm = this
			let cartObject = this.$store.state.cart.cartItems
			let userObject = this.$store.state.login.login
			let passengerObject = this.$store.state.psgdetails.passengers
			let orderDetails = []
			orderDetails.push({userid: userObject.user.Agtid, username: userObject.user.AgtName})
			orderDetails.push(cartObject)
			orderDetails.push(passengerObject)
			orderDetails.push({subtotal: this.subtotal})
			orderDetails.push({grandtotal: this.grandtotal})

			await vm.$store.dispatch("cart/checkoutCart", [orderDetails]).then((response) => {
				if(response && response.status == 200) {
					vm.$store.dispatch('cart/emptyCart')
					vm.$store.dispatch('psgdetails/emptyPsg')
					vm.msg = 'Order Processed Successfully'
					return setTimeout(() => {
						vm.$router.push('/packages')
					}, 2000)
				}
			}).catch((error) => {
				console.debug(error)
			})
		}
	}
}


</script>


<style lang="scss" scoped>
.cart-contain {
	// background: url('../assets/backgroundColor.png');
}

.cart {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 30px 0 10px 0;
	font-weight: 400;
	font-family: "Open Sans", sans-serif;
	font-size: 20px;
	color: #212121;
	// background:#212121;
	border-bottom: .5px solid #E65100;
	padding: 5px;
}

.cartItem {
	width: 100%;
	margin: 0;
}

.itemCard {
	height: 160px;
	// padding: 4px 0;
	margin: 10px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 5px;
	-webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
	-moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
	box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
	background-color: white;
}

.img {
	// background: #212121;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-position: center;
	background-clip: cover;
}
.back {
	height: 30px;
	cursor: pointer;
	margin-bottom: 20px;
}
.back:hover {
	-webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
	-moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
	box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
}

.back:focus {
	outline: none!important;
}

.sidebar {
	border-radius: 4px;
	-webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
	-moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
	box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
	height: 100%;
	padding: 0 5%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background-color: white;
}

.placeorder {
	width: 100%;
	border: none;
	-webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
	-moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
	box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
	font-weight: 300;
	background: #EEEEEE;
	transition: all 500ms ease-in-out;
	color: #212121;
}

.placeorder:disabled {
	width: 100%;
	border: none;
	-webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
	-moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
	box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
	font-weight: 300;
	background: #EEEEEE;
	transition: all 500ms ease-in-out;
	color: #212121;
}

.placeorder:hover {
	background: #212121;
	transition: all 500ms ease-in-out;
	color: white;
}

.placeorder:focus {
	outline: none!important;
}

sub {
	font-size: 8px;
}

.emptycart {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.psg-button {
	background: #212121;
	color: white;

	&:hover, &:focus {
		background: #fafafa;
		color:#212121;
		transition: all 500ms ease-in-out;
	}
}

.psgdetails {
	width: 15%;
	background: rgb(0, 150, 136);
	color: white;
	border: none!important;
	outline: none;

	&:hover {
		background: rgb(0, 150, 136);
	}

	:focus {
		outline: none;
	}

	button {
		opacity: 1!important;
	}
}

.delete-icon
{
	cursor: pointer;
}

.msg {
	width: 100%;
	background: #4CAF50;
	height: 30px;
	font-size: 14px;
	text-align: center;
	color: #212121;
}

</style>
